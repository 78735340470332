<template>
  <div class="animated fadeIn">
    <!-- ADMIN -->
    <template v-if="isAdmin">
      <b-card title="N Zone Sports" sub-title="Administrative Interface">
        <h3 class="card-text">Welcome</h3>
        <p
          class="card-text"
        >...to the your new administrative interface! This is the dashboard. When it is finished, you will have a glimpse of the business as a whole. For example, total franchises, total participants, stats relative to new signups per franchise, number of events currently active per franchise, etc...</p>
        <p
          class="card-text"
        >This is something you should think about while development is in pregress; what overall information you would like to see on your dashboard.</p>
      </b-card>

      <b-row>
        
       <!-- USERS -->
        <b-col cols="12" lg="6"> 
          <b-card title="Users">
            <p class="card-text">View existing users or add a new one</p>
            <b-button-group size="sm">
              <b-button href="#" variant="warning" to="/user/list-admins">View Admins</b-button>
              <b-button href="#" variant="primary" to="/user/list-directors">View Directors</b-button>
              <b-button href="#" variant="success" to="/user/form">Add a User</b-button>
            </b-button-group>
          </b-card>
        </b-col>

       <!-- FRANCHISES -->
        <b-col cols="12" lg="6"> 
          <b-card title="Franchises">
            <p class="card-text">View existing franchises or add a new one</p>
            <b-button-group size="sm">
              <b-button href="#" variant="primary" to="/business/list">View Franchises</b-button>
              <b-button
                href="#"
                variant="success"
                :to="{ path: '/user/form', query: { role: 'ROLE_DIRECTOR' }}"
              >Create a Franchise</b-button>
            </b-button-group>
          </b-card>
        </b-col>
      </b-row>

      <b-row>
        <!-- REPORTS -->
        <b-col cols="12" lg="6">
          <b-card title="Reports">
            <p class="card-text">View Franchise Reports</p>
            <b-button-group size="sm">
              <b-button variant="primary" :to="{ name: 'revenueOverview' }">Revenue Overview</b-button>
              <b-button variant="primary" :to="{ name: 'reportsRevenueList' }">Revenue</b-button>
              <b-button variant="primary" :to="{ name: 'reportsMarketingList' }">Marketing</b-button>
            </b-button-group>
          </b-card>
        </b-col>
        
        <!-- SUPPPORT -->
        <b-col cols="12" lg="6">
          <b-card title="Support Center">
            <p class="card-text">View Support Related Documents</p>
            <b-button-group size="sm">
              <b-button href="#" variant="primary" to="/support">View Support Center</b-button>
            </b-button-group>
          </b-card>
        </b-col>
      </b-row>

      <!-- CONTENT -->
      <b-row>
        <b-col cols="12" lg="6">
          <b-card title="Website">
            <p class="card-text">View / edit website content</p>
            <b-button-group size="sm">
              <b-button href="#" variant="primary" to="/website/sports-offered">View Sports</b-button>
            </b-button-group>
          </b-card>
        </b-col>
      </b-row>
    </template>

    <!-- DIRECTOR -->
    <template v-if="isDirector">
      <b-card title="N Zone Sports" sub-title="Administrative Interface">
        <b-card-body>
          <p v-if="currentBusiness && currentBusiness.primaryContact" class="card-text">
            {{ currentBusiness.primaryContact.fullName }} | {{ currentBusiness.phone }} |
            {{currentBusiness.email}}
            <br />
            <a
              :href="'https://' + sitePublicDomain + '/' + currentBusiness.uriName"
              target="_blank"
            >N Zone Sports - {{currentBusiness.areaName}}</a> (
            <a
              :href="'https://' + sitePublicDomain + '/' + currentBusiness.uriName"
              target="_blank"
            >{{ sitePublicDomain }}/{{ currentBusiness.uriName }}</a>)
          </p>
          <p class="card-text">
            <a
              href="#"
              @click.prevent="directorShowCoverage = !directorShowCoverage"
            >Show/Hide Territory Coverage</a>
            <span
              v-if="!!directorShowCoverage"
              class="animated fadeIn"
            >: {{ currentBusinessCoverage }}</span>
          </p>
        </b-card-body>
      </b-card>

    <b-row>
         <b-col cols="12" lg="6"> 
            <b-card title="Events" sub-title="View your events and mange event details.">
                <b-card-body>
                <b-button-group>
                    <b-btn to="/events/all" variant="info">View Events</b-btn>
                    <b-btn to="/events/new" variant="primary">New Event</b-btn>
                </b-button-group>
                </b-card-body>
            </b-card>
        </b-col>
         <b-col cols="12" lg="6"> 
            <b-card title="Happening Now" sub-title="View your Happening Now posts.">
                <b-card-body>
                <b-button-group>
                    <b-btn to="/website-local/happening-now" variant="info">View Happening Now Posts</b-btn>
                    <b-btn to="/website-local/happening-now/form" variant="primary">New Happening Now Post</b-btn>
                </b-button-group>
                </b-card-body>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
         <b-col cols="12" lg="6"> 
            <b-card title="Participants">
                <p class="card-text">
                View your events'
                <router-link :to="{ name: 'participantList' }" class="card-link">participants</router-link> and mange their details.
                </p>
            </b-card>
        </b-col>
         <b-col cols="12" lg="6"> 
            <b-card title="Who We Are">
                <p class="card-text">
                Edit your "<router-link
                  :to="{name: 'Pages', query: {page: 'about'}}"
                  class="card-link"
                >Who We Are</router-link>" page content.
                </p>
            </b-card>
        </b-col>
    </b-row>

    <b-row>
         <b-col cols="12" lg="6"> 
            <b-card title="Public Settings">
                <p class="card-text">
                Edit your
                <router-link :to="{name: 'businessPublicData'}" class="card-link">Public Settings</router-link>,
                including contact methods, social media and sports offered.
                </p>
            </b-card>

        </b-col>
         <b-col cols="12" lg="6"> 
            <b-card title="Sponsors">
                <p class="card-text">
                Edit your
                <router-link :to="{name: 'sponsors'}" class="card-link">Sponsors</router-link>.
                </p>
            </b-card>
        </b-col>
    </b-row>
    </template>

    <!-- COACH -->
    <template v-if="isCoach">
      <!-- b-card title="N Zone Sports"
                sub-title="Administrative Interface">
            <b-card-body>
                <p class="card-text">
                    Welcome Coach!
                </p>
            </b-card-body>
      </b-card-->
      <b-card title="My Events" sub-title="Current and Upcoming Events">
        <b-card-body>
          <p>Your Current Events:</p>
          <coach-events></coach-events>
        </b-card-body>
      </b-card>
    </template>

    <!-- COACH JR -->
    <template v-if="isCoachJr">
      <!-- b-card title="N Zone Sports"
                sub-title="Administrative Interface">
            <b-card-body>
                <p class="card-text">
                    Welcome Coach!
                </p>
            </b-card-body>
      </b-card-->
      <b-card title="My Events" sub-title="Current and Upcoming Events">
        <b-card-body>
          <p>Your Current Events:</p>
          <coach-events></coach-events>
        </b-card-body>
      </b-card>
    </template>
  </div>
</template>

<script>
import CoachEvents from "@/components/CoachEvents"

export default {
  name: "dashboard",
  data() {
    return {
      directorShowCoverage: false
    };
  },
  computed: {
    userData() {
      return this.$store.getters.userData
    }
  },
  components: { CoachEvents }
}
</script>
