<template>
  <div class="animated fadeIn">
    <div v-for="(eventGroup, index) in eventGroups" :key="index" class="border-top py-2">
      <div class="mb-2">
      <router-link
        size="sm"
        v-b-tooltip.hover 
        :to="{ name: 'eventDetails', params: { eid: eventGroup.event.id } }"
        title="View event details"
      >
        {{ eventGroup.event.title.toUpperCase() }}
      </router-link>
      </div>
      <b-btn
        size="sm"
        v-b-tooltip.hover 
        :to="{ name: 'eventGroup', params: { egid: eventGroup.id } }"
        title="View Roster"
      >
        Team: {{ eventGroup.name }} ({{ eventGroup.ageMin }} - {{ eventGroup.ageMax }})
      </b-btn>
      <b-btn
        class="ml-1"
        size="sm"
        v-b-tooltip.hover
        title="Message all primary contacts"
        :to="{ name: 'Compose', params: { pgid: eventGroup.id } }"
      >
        <i class="fa fa-envelope"></i> Message Group
      </b-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'CoachEvents',
  computed: {
    ...mapGetters([
      'eventGroups'
    ])
  },
  methods: {
    ...mapActions([
      'getEventGroups'
    ])
  },
  mounted () {
    this.getEventGroups({
      coach: this.currentUser.id
    })
  }
}
</script>